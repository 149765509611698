import { Injectable, InjectionToken, PLATFORM_ID, effect, inject } from "@angular/core";
import { Web3Modal } from "@web3modal/ethers/dist/types/src/client";
import { ThemeSwitcherService } from "../../feature/theme-switcher.service";
import { isPlatformBrowser } from "@angular/common";
import {
  NetworkChainId,
  WalletAddress,
  getDevPortalWalletAddress,
  getTokenAddresses
} from "@libs/shares/models/wallet.model";
import { UnleashToggleService } from "../unleash/unlease.service";
import { map } from "rxjs";
import { createSignal } from "ngxtension/create-signal";
import { toSignal } from "@angular/core/rxjs-interop";
import { computedAsync } from "ngxtension/computed-async";

export const WALLET_CONNECT_PROJECT_ID = new InjectionToken<string>("__WALLET_CONNECT_PROJECT_ID__");

const ECR20_ABI = [
  "function transfer(address to, uint amount)",
  "function balanceOf(address owner) view returns (uint256)"
];

@Injectable({
  providedIn: "root"
})
export class WalletConnectService {
  private themeService = inject(ThemeSwitcherService);
  private walletConnectProjectId = inject(WALLET_CONNECT_PROJECT_ID);
  private unleashService = inject(UnleashToggleService);
  private modal = createSignal<Web3Modal | null>(null);

  activeChainId = toSignal(
    this.unleashService
      .getVariant$("paymentNetwork")
      .pipe(
        map((variant) => (variant.payload?.value ? (parseInt(variant.payload?.value) as NetworkChainId) : undefined))
      )
  );
  isConnected = createSignal(false);
  address = createSignal<WalletAddress | undefined>(undefined);
  chainId = createSignal<number | undefined>(undefined);
  walletIcon = createSignal<string | null>(null);
  balances = computedAsync<{
    usdc: number;
    ethereum: number;
  } | null>(
    async () => {
      const activeChainId = this.activeChainId();
      const walletChainId = this.chainId();
      const address = this.address();
      const isConnected = this.isConnected();
      if (activeChainId && address && activeChainId === walletChainId && isConnected) {
        return import("ethers").then(async ({ formatUnits, Contract }) => {
          const provider = await this.ethersProvider;
          const signer = await provider.getSigner();
          try {
            const contractUsdc = new Contract(getTokenAddresses(this.activeChainId()).USDC, ECR20_ABI, signer);
            const balanceUsdc = await contractUsdc["balanceOf"](address);

            return {
              usdc: parseFloat(formatUnits(balanceUsdc, 6)),
              ethereum: parseFloat(formatUnits(await provider.getBalance(address), 18))
            };
          } catch (error) {
            console.log(error);
            return null;
          }
        });
      }
      return null;
    },
    { initialValue: null }
  );

  constructor() {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      effect(
        async () => {
          const activeChainId = this.activeChainId();
          if (activeChainId) {
            const modal = await this.createModal(this.walletConnectProjectId);
            this.modal.set(modal);

            modal.subscribeProvider(({ address, chainId, isConnected }) => {
              this.isConnected.set(isConnected);
              this.chainId.set(chainId);
              if (address && isConnected) {
                const lowerCaseAddress = `0x${address.slice(2).toLowerCase()}` as WalletAddress;
                this.address.set(lowerCaseAddress);
                this.loadConnectedWalletIcon().then((data) => {
                  if (data) {
                    this.walletIcon.set(data);
                  }
                });
              }
            });
          }
        },
        { allowSignalWrites: true }
      );

      effect(() => {
        this.modal()?.setThemeMode(this.themeService.currentTheme() === "onDark" ? "dark" : "light");
      });
    }
  }

  private loadConnectedWalletIcon: () => Promise<string | null> = () => Promise.resolve(null);

  get ethersProvider() {
    return import("ethers").then(({ BrowserProvider }) => {
      const provider = this.modal()?.getWalletProvider();

      if (!provider) {
        throw new Error("No provider connected");
      }
      return new BrowserProvider(provider);
    });
  }

  open() {
    this.modal()?.open();
  }

  disconnect() {
    this.modal()?.disconnect();
  }

  /**
   * Calculate the gas unit for the given USDC amount via RPC node.
   *
   * @param usdcAmount e.g. 499.00 USDC
   * @returns gas unit as bigint
   */
  async estimateGasUnits(usdcAmount: number) {
    const { ethers } = await import("ethers");
    const { parseUnits } = await import("ethers");
    const provider = await this.ethersProvider;
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(getTokenAddresses(this.activeChainId()).USDC, ECR20_ABI, signer);

    // we calculate how much the transfer function on the smart contract would cost in gas units
    try {
      const gasUnits = await contract["transfer"].estimateGas(
        getDevPortalWalletAddress(this.activeChainId()),
        parseUnits((Math.round(usdcAmount * 100) / 100).toString(), 6)
      );
      return gasUnits;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 3) {
        console.error("transfer amount exceeds balance");
      }
    }

    return null;
  }

  async getGasPrice() {
    return (await (await this.ethersProvider).getFeeData()).gasPrice;
  }

  async sendTransaction(usdcAmount: number, gasLimit: string, maxPriorityFeePerGas: string, maxFeePerGas: string) {
    const { parseUnits } = await import("ethers");
    const { ethers } = await import("ethers");

    const provider = await this.ethersProvider;
    const signer = await provider.getSigner();

    const contract = new ethers.Contract(getTokenAddresses(this.activeChainId()).USDC, ECR20_ABI, signer);

    const tx = await contract["transfer"](
      getDevPortalWalletAddress(this.activeChainId()),
      parseUnits(usdcAmount.toString(), 6),
      {
        gasLimit: gasLimit,
        maxPriorityFeePerGas: maxPriorityFeePerGas,
        maxFeePerGas: maxFeePerGas
      }
    );

    return tx;
  }

  async switchNetwork(chainId: NetworkChainId) {
    const { toQuantity } = await import("ethers");

    const provider = this.modal()?.getWalletProvider();
    if (!provider) {
      throw new Error("No provider connected");
    }

    try {
      return provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: toQuantity(chainId)
          }
        ]
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === "Missing or invalid. request() method: wallet_switchEthereumChain") {
        throw new Error("SWITCH_NETWORK_NOT_SUPPORTED_BY_WALLET");
      }
      throw error;
    }
  }

  private async createModal(projectId: string): Promise<Web3Modal> {
    const mainnet = {
      chainId: 1,
      name: "Ethereum",
      currency: "ETH",
      explorerUrl: "https://etherscan.io",
      rpcUrl: "https://web3-node.1inch.io"
    };
    const sepolia = {
      chainId: 11155111,
      name: "Sepolia",
      currency: "SepoliaETH",
      explorerUrl: "https://sepolia.etherscan.io",
      rpcUrl: "https://rpc.sepolia.org"
    };

    const sitepath = `${window.location.protocol}//${window.location.host}`;
    const metadata = {
      name: document.title,
      description: document.querySelector('meta[name="description"]')?.attributes.getNamedItem("content")?.value ?? "",
      url: sitepath,
      icons: [`${sitepath}/assets/logo-small.svg`]
    };

    const { createWeb3Modal, defaultConfig } = await import("@web3modal/ethers");
    const { loadConnectedWalletIcon } = await import("./connected-wallet-icon-storage");
    this.loadConnectedWalletIcon = loadConnectedWalletIcon;
    const supportedChains = [mainnet];
    if (this.activeChainId() === NetworkChainId.Sepolia) {
      supportedChains.push(sepolia);
    }

    const modal = createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: supportedChains,
      defaultChain: mainnet,
      projectId,
      enableSwaps: false,
      enableOnramp: false,
      tokens: {
        [NetworkChainId.EthereumMainnet]: {
          address: getTokenAddresses(NetworkChainId.EthereumMainnet).USDC
        },
        [NetworkChainId.Sepolia]: {
          address: getTokenAddresses(NetworkChainId.Sepolia).USDC
        }
      }
    });

    return modal;
  }
}
