export type WalletAddress = `0x${Lowercase<string>}`;

enum TokenAddressEnum {
  USDC = "USDC",
  Ethereum = "Ethereum"
}

export enum NetworkChainId {
  EthereumMainnet = 1,
  Sepolia = 11155111
}

const tokenAddresses: Record<NetworkChainId, Record<TokenAddressEnum, WalletAddress>> = {
  [NetworkChainId.EthereumMainnet]: {
    USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    Ethereum: "0x0000000000000000000000000000000000000000" as WalletAddress
  },
  [NetworkChainId.Sepolia]: {
    USDC: "0xf667d515097d312fefeb7f7afdba15d57aae30d4",
    Ethereum: "0x0000000000000000000000000000000000000000"
  }
};

const devPortalWalletAddress: Record<NetworkChainId, WalletAddress> = {
  [NetworkChainId.EthereumMainnet]: "0x1e43c5f5244eaee3e170bad145bf80b841196564",
  [NetworkChainId.Sepolia]: "0xa0333709c6be47412637084f4dbf8b7e8f271888"
};

export const getTokenAddresses = (network: NetworkChainId = NetworkChainId.Sepolia) => tokenAddresses[network];

export const getDevPortalWalletAddress = (network: NetworkChainId = NetworkChainId.Sepolia) =>
  devPortalWalletAddress[network];

const scanBaseUrls = {
  [NetworkChainId.EthereumMainnet]: "https://etherscan.io",
  [NetworkChainId.Sepolia]: "https://sepolia.etherscan.io"
};

export const getChainScanBaseUrl = (network: NetworkChainId = NetworkChainId.Sepolia) => scanBaseUrls[network];

const minimumConfirmedBlocks = {
  [NetworkChainId.EthereumMainnet]: 10,
  [NetworkChainId.Sepolia]: 1
};

export const getMinimumConfirmedBlocks = (network: NetworkChainId = NetworkChainId.Sepolia) =>
  minimumConfirmedBlocks[network];
